import axios from "axios";

window.onloadCallback = function() {
  grecaptcha.render('recaptcha', {
    'sitekey' : '6LfI8yYUAAAAAN7zZzpxo9pArUHvmMD1MMlOnCTd'
  });
};

let form = document.querySelector('#health_care_heroes_form');
if (form) {
  form.addEventListener('submit', function(event) {
    event.preventDefault();
  
    let formContainer = document.querySelector('#health_care_heroes');
  
    let gResponse = "";
  
    if (!grecaptcha.getResponse() || !gResponse.length) {
      gResponse = grecaptcha.getResponse();
  
      if (gResponse.length) {
          const formData = new FormData(form);
          const formStringify = Array.from(formData,
            e => e.map(encodeURIComponent).join('=')
          ).join('&');
          const mailingListActionURL = `${window.settings.yoursole_url}/${window.settings.region}/mailing_list/${window.settings.webstore_mailing_list}/subscribe`;
          axios.get(`${mailingListActionURL}?${formStringify}`).then(res => {
            formContainer.querySelector('.modal-body').innerHTML = res.data;
            setTimeout(function () {
              $(formContainer).modal('hide');
            }, 7000);
          }).catch(err => {
            formContainer.querySelector('.modal-body').innerHTML = err.data;
          });
      } else {
        console.error("Error: no recaptcha response from Google", gResponse);
        return;
      };
    };
  });
}
